import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function EnquiryPopup(props) {
    const [file, setFile] = useState();
    const [filename, setFilename] = useState();

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    function handleChange(e) {
        console.log(e.target.files);

        setFilename(e.target.files[0].name);
        setFile(URL.createObjectURL(e.target.files[0]));

    }
    return (
        props.trigger
    ) ? (
        <div className='popup'>

            <div className='popup-inner'>
                <button className='close-btn nav-btn' onClick={() => props.setTrigger(false)}><i className="fa-solid fa-xmark"></i></button>
                <div className="form-section">
                    {/* Button Popup */}
                    <div className="contact-area">
                        <div className="contact-form">
                            <div className="col-lg-12 pt-5 pb-4">
                                <div className="px-4">

                                    <form action="">
                                        <div className="row">
                                            <div className="col-lg-6 mb-4">
                                                <label>Name</label>
                                                <input type="text" placeholder="Enter Name" />
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <label>Company Name</label>
                                                <input type="text" placeholder="Enter Company Name" />
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <label>Contact Number</label>
                                                <input type="text" placeholder="Enter Contact Number" />
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <label>Email</label>
                                                <input type="email" placeholder="Enter Email" />
                                            </div>
                                            <div className="col-lg-6 mb-4">

                                                <label>Type Of Structure</label>
                                                <select className="form-select  mb-3" aria-label=".form-select-lg example">
                                                    <option selected>Select Type Of Structure</option>
                                                    <option value="1">PEB Shed</option>
                                                    <option value="2">Oil and Gas Plant</option>
                                                    <option value="2">Structural Audit</option>
                                                    <option value="3">Real Estate</option>
                                                </select>

                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <label>Dimensions In</label>
                                                <select className="form-select mb-3" aria-label=".form-select-lg example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1">Meter</option>
                                                    <option value="2">Feet</option>

                                                </select>

                                            </div>
                                            <div className="col-lg-12 mb-3">


                                                <label>Dimensions Of Plots:</label>
                                            </div>

                                            <div className="col-lg-6 mb-4">




                                                <label>Length</label>
                                                <input type="text" placeholder="Enter Length" />

                                            </div>
                                            <div className="col-lg-6 mb-4">


                                                <label>Width</label>
                                                <input type="text" placeholder="Enter Width" />


                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <img className='upload-img' src={file} />
                                                <span className="upld-btn" onClick={handleClick}>
                                                    <i className="bi bi-upload"></i>  Upload an Image
                                                </span>
                                                <input type="file"
                                                    ref={hiddenFileInput}
                                                    onChange={handleChange}
                                                    style={{ display: 'none' }}
                                                />
                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <label>Size of structure</label>
                                            </div>
                                            <div className="col-lg-4 mb-4">
                                                <label>No. of Structures</label>
                                                <input type="number" placeholder="Enter Width" />
                                            </div>
                                            <div className="col-lg-4 mb-4">
                                                <label>Length</label>
                                                <input type="text" placeholder="Enter Length" />

                                            </div>
                                            <div className="col-lg-4 mb-4">


                                                <label>Width</label>
                                                <input type="text" placeholder="Enter Width" />


                                            </div>
                                            <div className="col-lg-4 mb-4">


                                                <label>Clear Height</label>
                                                <input type="text" placeholder="Enter Clear Height" />


                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <label>Building Type</label>
                                                <select className="form-select mb-3" aria-label=".form-select-lg example">
                                                    <option selected>Select Building Type</option>
                                                    <option value="1">Clear Span</option>
                                                    <option value="2">Intermediate</option>

                                                </select>

                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <label>Building Type</label>
                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                                <label className="form-check-label me-4" for="flexRadioDefault1">
                                                    Yes
                                                </label>
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    No
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-4">


                                                <label>Height</label>
                                                <input type="text" placeholder="Enter Wall Height" />


                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <label>No. Of Openings</label>
                                            </div>
                                            <div className="col-lg-6 mb-4">


                                                <label>No. Of Openings</label>
                                                <input type="number" placeholder="Enter No. of Openings" />


                                            </div>

                                            <div className="col-lg-4 mb-4">
                                                <label>Size</label>
                                                <input type="text" placeholder="Enter Size" />
                                            </div>
                                            <div className="col-lg-4 mb-4">
                                                <label>Length</label>
                                                <input type="text" placeholder="Enter Length" />

                                            </div>
                                            <div className="col-lg-4 mb-4">


                                                <label>Width</label>
                                                <input type="text" placeholder="Enter Width" />


                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <label>Sheeting Type</label>
                                                <select className="form-select mb-3" aria-label=".form-select-lg example">
                                                    <option selected>Select Sheeting Type</option>
                                                    <option value="1">Galvallium Sheet</option>
                                                    <option value="2">Standing Seems</option>
                                                    <option value="3">Puff Panels</option>

                                                </select>

                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <label>Crane</label>
                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault1" checked />
                                                <label className="form-check-label me-4" >
                                                    Yes
                                                </label>
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault1" />
                                                <label className="form-check-label" >
                                                    No
                                                </label>
                                            </div>
                                            <div className="col-lg-6 mb-4">


                                                <label>Capacity</label>
                                                <input type="text" placeholder="Enter Wall Height" />


                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <label>No. Of Turbos</label>
                                            </div>
                                            <div className="col-lg-6 mb-4">


                                                <label>Turbo</label>
                                                <input type="number" placeholder="Enter No. of Openings" />


                                            </div>
                                            <div className="col-lg-6 mb-4">


                                                <label>Skylights</label>
                                                <input type="number" placeholder="Enter No. of Openings" />


                                            </div>


                                            <div className="col-lg-12 mb-0">
                                                <label>Conapy</label>
                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault2" checked />
                                                <label className="form-check-label me-4" >
                                                    Yes
                                                </label>
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault2" />
                                                <label className="form-check-label" >
                                                    No
                                                </label>
                                            </div>
                                            <div className="col-lg-4 mb-4">
                                                <label>Width</label>
                                                <input type="text" placeholder="Enter Width" />
                                            </div>
                                            <div className="col-lg-4 mb-4">
                                                <label>Height</label>
                                                <input type="text" placeholder="Enter Height" />

                                            </div>
                                            <div className="col-lg-4 mb-4">


                                                <label>NOS</label>
                                                <input type="text" placeholder="Enter NOS" />


                                            </div>


                                            <div className="col-lg-12 mb-0">
                                                <label>Design Code</label>
                                            </div>
                                            <div className="col-lg-12 mb-4">
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault1" checked />
                                                <label className="form-check-label me-4" >
                                                    Indian
                                                </label>
                                                <input className="form-check-input me-2" type="radio" name="flexRadioDefault1" />
                                                <label className="form-check-label" >
                                                    AISC
                                                </label>
                                            </div>
                                        
                                        </div>
                                        <div className="">
                                            <Link className="nav-btn" to="/">Submit</Link>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>

                </div >
            </div>
        </div>
    ) : "";
}

export default EnquiryPopup