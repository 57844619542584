import { createContext, useContext, useState } from 'react';

const DataContext = createContext();



export const DataProvider = ({ children }) => {
    const [ProjectViewData, setProjectViewData] = useState({
        p1: "Bedi Group undertook an ambitious real estate project at 193 Drumond Street, a testament to our commitment to excellence and innovation in the field of real estate development.",
        p2: "Our team meticulously planned every aspect of the project, from the initial site selection to the final construction. We ensured that the project adhered to all regulatory requirements and was completed within the stipulated timeline and budget.",
        p3: "We took care of everything, from coordinating with contractors and architects to managing the construction process. Our hands-on approach ensured that every detail was taken care of, resulting in a project that met our high standards of quality and design.",
        p4: "Today, the project at 193 Drumond Street stands as a symbol of our dedication and hard work. It represents our passion for creating spaces that enhance community and connection, and our commitment to delivering projects that exceed expectations.",
        image1: "assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
        image2: "assets/website-images/Oil-and-Gas-pics/biryani.jpeg",
    },);
    let [Index, setIndex] = useState(1);


    const getId = (takeId) => {
        setProjectViewData(takeId)
    }
    
    const getIndex = (takeIndex) => {
        setIndex(takeIndex)
    }

    return (
        <DataContext.Provider value={{ ProjectViewData, Index, getId, getIndex }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataContext
