import React from "react";
import { Controller, Scene } from "react-scrollmagic";

const SectionWipes = (page) => {

  return (
    <div className="over-hide">
      <Controller globalSceneOptions={{ triggerHook: "onLeave" }}>
        <Scene pin>
          <div
            className="panel blue"
            style={{ backgroundImage: `url(${page.img})` }}
            id="popup"
          >
            <span className={ page.title === 'Trusted & Secure Real Estate Developer.' ? "hero-title realStateHeading" : "hero-title"}>{page.title}</span>
          </div>
        </Scene>
      </Controller>
    </div>
  )
}



export default SectionWipes;
