import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import VideoPlayer from "../SubComponents/VideoPlayer";
import Testimonials from "../SubComponents/Testimonials2";
import Clients from "../SubComponents/Clients";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import Blogs from "../SubComponents/Blogs";
import OilGasBg from "./../Images/header/oil&gas.png";
import PebBg from "./../Images/header/peb.png";
import BimBg from "./../Images/header/bim.png";
import StructureBg from "./../Images/header/structure-bg1.jpeg";
import SustainBg from "./../Images/header/sustainale.jpg";
import RealEstateBg from "./../Images/header/real-estate.png";

import Popup from "../Components/Popup";
import attachBg from "./../Images/attach-bg.jpeg";
import { HashLink } from "react-router-hash-link";
import EnquiryPopup from "../Components/EnquiryPopup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EnquiryForm from "../Forms/EnquiryForm";
import AOS from "aos";
import "aos/dist/aos.css";
import EnquiryOilGas from "../Forms/EnquiryOilGas";
import EnquiryStructuralAudit from "../Forms/EnquiryStructuralAudit";
import EnquiryRealEstate from "../Forms/EnquiryRealEstate";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { MotionAnimate } from "react-motion-animate";
import Slider from "../SubComponents/slider";

function Home(props) {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const [showOilGasModal, setValueOilGas] = useState(false);
  const [showPEBShedModal, setValuePEBShed] = useState(false);
  const [showRealEstate, setValueRealEstate] = useState(false);
  const [showStructuralAudit, setValueStructuralAudit] = useState(false);
  const [categoryStructure, setCategoryStructure] = useState("");
  const [showFormModal, setShowModal] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [focus, setFocus] = React.useState(false);
  const [timedPopup, setTimedPopup] = useState(false);
  const [color, setColor] = useState(false);

  const handleClose = () => setShowModal(false);

  const handleShow = () => {
    // setValuePEBShed(false);
    // setValueOilGas(false);
    // setValueRealEstate(false);
    // setValueStructuralAudit(false);

    // if (categoryStructure === "PEB Shed") setValuePEBShed(true);
    // else if (categoryStructure === "Oil and Gas") setValueOilGas(true);
    // else if (categoryStructure === "Real Estate") setValueRealEstate(true);
    // else if (categoryStructure === "Structural Audit")
    //   setValueStructuralAudit(true);
    // setShowModal(true);
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimedPopup(true);
  //   }, 15000);
  // }, []);

  const changeColor = () => {
    if (window.scrollY >= 250) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  function structureMineChangeUrl(url) {
    window.location = url;
  }

  return (
    <>
      <Popup trigger={timedPopup} setTrigger={setTimedPopup}></Popup>

      <EnquiryPopup
        trigger={buttonPopup}
        setTrigger={setButtonPopup}
      ></EnquiryPopup>
      <div className={color ? "color-header home-scroll" : "home-scroll"}>
        <Header />
        <div className="intel-hero">
          <VideoPlayer />
          <div className="menu-for-large">
            <MotionAnimate animation="scrollFadeOut">
              <ul className="menu ps-0">
                <li className="menu-item">
                  <NavLink
                    to="/about"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    About
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    to="/products"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Frozen Food
                  </NavLink>
                </li> 
                <li className="menu-item">
                  <HashLink to="/#service">Sectors</HashLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    to="/projects"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Portfolio
                  </NavLink>
                </li>
              </ul>
            </MotionAnimate>
          </div>
          <div className="hero-overlay"></div>
        </div>
      </div>
      <div className="numbers-section">
        <div className="w-100">
          <div className="number-area" id="popup1">
            <div className="row  slide-anim" style={{ marginLeft: "0" }}>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1>
                      <CountUp
                        start={focus ? 0 : null}
                        end={11}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      
                      <span style={{ fontFamily: "" }} className="ms-2">
                        {/* Years */}
                      </span>
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2>Completed Projects </h2>
                  </div>
                </div>
              </div>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1>
                      {/* <span>&#36; </span> */}
                      <CountUp
                        style={{ fontFamily: "" }}
                        start={focus ? 0 : null}
                        end={5}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2>Restaurants</h2>
                  </div>
                </div>
              </div>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1 style={{ fontFamily: "" }}>
                      <CountUp
                        style={{ fontFamily: "" }}
                        start={focus ? 0 : null}
                        end={4}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <span style={{ fontFamily: "" }} className="ms-2">
                        {/* Lac */}
                      </span>
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2>Frozen Food Category</h2>
                  </div>
                </div>
              </div>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1>
                      <CountUp
                        start={focus ? 0 : null}
                        end={4}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      
                      {/* <span style={{ fontFamily: "" }} className="ms-2">
                        Franchise Chain
                      </span> */}
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2> Franchise Chain </h2>
                  </div>
                </div>
              </div>
              <div className="animation start-home"></div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="services-section bg-dark-new pb-5"> */}
        {/* <div className="w-100  pt-5" >
          <div
            className="contact-heading  color-cont-head text-center mb-30 max-600 mx-auto"
            data-aos="fade-up"
          >
            <h1>
              {" "}
              {/* <span style={{ color: "#9966CC" }}>i</span>Bexplorer */}

             {/* Invest In Canada
            </h1>
            <p>
              With Bedi Group
            </p>
          </div>
          

          <Slider />
          
        </div> */}
      {/* </div> */}
      <div className="w-100" id="service">
        <div
          className="contact-heading max-600 mx-auto text-center px-4 mb-4"
          data-aos="fade-up"
        >
          <h1>Invest In Canada</h1>

          <p className="home-p">
          With Bedi Group
          </p>
        </div>
        <div className=" w-100">
          <div className="row home-serv w-100">
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${OilGasBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/oil-and-gas">Food Processing Business </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                        Bedi Group, as a Canadian food processing company, is well-positioned to thrive in the evolving landscape of the industry. Through a commitment to innovation, adaptation to changing consumer preferences, and strategic alignment with global market trends, Bedi Group stands to contribute significantly to the continued growth of the Canadian food processing sector.                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    {/* <img src="" alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${PebBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="peb-structures">Restaurant Business Portfolio</Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Bedi Group, with its diversified restaurant portfolio, is a key player in Canada's vibrant culinary scene. Through a commitment to innovation, adaptation to consumer preferences, and strategic alignment with industry trends, Bedi Group is likely to continue making a significant impact on the Canadian restaurant business.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${RealEstateBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/real-estate">Pharma Business Portfolio </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Bedi Group, a distinguished Canadian company, has diversified its portfolio to include a significant presence in the pharmaceutical industry. The company is likely to play a pivotal role in the healthcare sector, contributing to the development, production, and distribution of pharmaceutical products.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${BimBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/bim-services">Real Estate Portfolio</Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                      Bedi Group, a distinguished Canadian company, has strategically positioned itself as a leading player in the real estate sector, specializing in turnkey projects. The company's portfolio likely encompasses a diverse range of real estate developments, including residential, commercial, and potentially mixed-use projects.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${SustainBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/sustainable-projects">
                          Sustainable Design & Engineering{" "}
                        </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Regardless of the type, size & location of the project
                          our environmental assessors & building physicists
                          understand their responsibility towards impacting
                          communities, & integrating sustainable design
                          solutions into engineering processes. Our
                          future-oriented advice & response to pioneering trends
                          through innovative design implementation will assist
                          you in achieving your sustainability goals & energy
                          efficient buildings.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${StructureBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/structural-audit">Structural Auditing </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Our licensed structural engineering experts,
                          well-informed & equipped with the latest tools &
                          technologies adopt the Non-Destructive Testing
                          techniques to asses and analyse your structure.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="py-5 px-4 w-100">
        <div className="contact-heading text-center mb-4" data-aos="fade-up">
          <h1>Our Valued Clients</h1>
        </div>
        <div className="contact-heading text-center max-600 mx-auto mb-4">
          <p>
            We are honored to collaborate with a diverse portfolio of clients, including esteemed government agencies, multinational corporations, and industry pioneers. Our clients trust us to drive their economic growth, and we take pride in delivering innovative
          </p>
          <p>Our commitment to excellence in trade, investment, and diplomacy is recognized on a global scale. Explore our esteemed clientele below.
            {" "}
          </p>
        </div>
        <Clients />
      </div>
      <MotionAnimate
        animation="scrollOpacity"
        ease={[0.17, 0.67, 0.97, -0.148]}
        scrollPositions={[0.2, 0.5, 0.6, 1]}
      >
        {/* <div className="testimonials pt-5 bg-sky">
          <div className="contact-heading text-center mb-4">
            <h1>Endorsements</h1>
          </div>

          <Testimonials />
        </div> */}
      </MotionAnimate>
      <div
        className="assistance request-area py-60"
        style={{ backgroundImage: `url(${attachBg})` }}
        id="popup"
      >
        <div className="request-overlay"></div>
        <div className="container-uid z-ind-3">
          <div
            className="about-heading  mx-auto text-center px-lg-4 mb-4"
            data-aos="fade-up"
          >
            <h1>Need Investment Assistance? Ask Us</h1>

            <p>
            Our dedicated team of professionals is committed to providing personalized 
            and timely assistance, guiding you through our diverse investment options 
            and ensuring your queries are addressed.{" "}
            </p>
          </div>
          <div className="row mb-4 assistance-steps">
            <div className="col-md-4 mb-4 px-3">
              <div className="steps-bx d-flex justify-content-center">
                <div className="step-no">
                  <span>1</span>
                </div>
                <div className="step-txt">
                  <span>
                    <i className="bi bi-check-circle step-icon" /> Select Your
                    Industry
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 px-3">
              <div
                className="steps-bx d-flex justify-content-center active"
                style={{ background: "transparent" }}
              >
                <div className="step-no">
                  <span style={{ background: "#000", color: "white" }}>
                    2
                  </span>
                </div>
                <div className="step-txt">
                  <span>
                    <i className="bi bi-cloud-upload"></i> Upload Enquiry
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 px-3">
              <div className="steps-bx d-flex justify-content-center">
                <div className="step-no">
                  <span>3</span>
                </div>
                <div className="step-txt">
                  <span>
                    <i className="bi bi-file-earmark-text"></i> Get In Touch
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center enquiry-sec" data-aos="fade-in">
            <div className="contact-area">
              <div className="contact-form">
                <form action="">
                  <div className="row">
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Name</label>
                      <input type="text" placeholder="Enter Name" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Company Name</label>
                      <input type="text" placeholder="Enter Company Name" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Contact Number</label>
                      <input type="text" placeholder="Enter Contact Number" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Email</label>
                      <input type="email" placeholder="Enter Email" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Type Of Services</label>
                      <select
                        className="form-select  mb-3"
                        aria-label=".form-select-lg example"
                        onChange={(e) => {
                          setCategoryStructure(e?.target?.value);
                        }}
                      >
                        <option selected>Select Category</option>
                        <option value="PEB Shed">Frozen Food
                        </option>
                        <option value="Oil and Gas">Restaurant
                        </option>
                        <option value="Real Estate">Pharma
                        </option>
                        <option value="Structural Audit">
                          Real Estate                       </option>
                      </select>
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Currency</label>
                      <input type="text" placeholder="Enter Currency" />

                    </div>
                  </div>
                </form>
                <button className="nav-btn-org w-33" onClick={handleShow}>
                  GET A FREE QUOTE
                </button>
              </div>
            </div>
            <Modal show={showFormModal} onHide={handleClose} animation={false}>
              <Modal.Header>
                <Modal.Title className="pl-3" style={{ fontSize: "20px" }}>
                  Select the Type of Structure
                </Modal.Title>
                <Button className="nav-btn" onClick={handleClose}>
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </Modal.Header>
              <Modal.Body>
                {showRealEstate && (
                  <EnquiryRealEstate categoryStructure={categoryStructure} />
                )}
                {showStructuralAudit && (
                  <EnquiryStructuralAudit
                    categoryStructure={categoryStructure}
                  />
                )}
                {showOilGasModal && (
                  <EnquiryOilGas categoryStructure={categoryStructure} />
                )}
                {showPEBShedModal && (
                  <EnquiryForm categoryStructure={categoryStructure} />
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>

      <div className="w-100 pb-5 pt-5 px-4">
        <div className="contact-heading text-center">
          <h1>Latest Insights</h1>
        </div>
        <Blogs />
      </div> 
      <Footer />
    </>
  );
}

export default Home;