import React from 'react'
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import emailjs from 'emailjs-com';


function FloatingPopup(props) {
  const form = useRef()
  function sendEmail(e) {
    e.preventDefault();

    console.log(form.current, "current")
    emailjs.sendForm('service_gv7h749', 'template_vnalpac', form.current, 'K49l0okANjKtyoz_w')
      .then((result) => {
        console.log(result.text);
        if (result.text) {
          window.location.reload()

          alert('form is submited')
        }
        // window.history.reload()
      }).catch((error) => {
        console.log(error.text);
        alert(error)
      });


  }

  return (
    props.trigger
  ) ? (
    <div className='popup'>
      <div className='popup-inner'>
        <button className='close-btn nav-btn' onClick={() => props.setTrigger(false)}><i className="fa-solid fa-xmark"></i></button>
        <div className="form-section">
          {/* Button Popup */}
          <div className="contact-area">
            <div className="contact-form">
              <div className="col-lg-12 pt-5 pb-4">
                <div className="px-4">


                  <form action="" ref={form} onSubmit={sendEmail}>
                    <div className="row">

                      <div className="col-lg-6 mb-4">




                        <label>First Name</label>
                        <input type="text" placeholder="Enter First name" name='toFirstName' />

                      </div>
                      <div className="col-lg-6 mb-4">


                        <label>Last Name</label>
                        <input type="text" placeholder="Enter Last name" name='toLastName' />


                      </div>
                      <div className="col-lg-6 mb-4">

                        <label>Email</label>
                        <input type="email" placeholder="Enter Email Address" name='toEmailId' />

                      </div>
                      <div className="col-lg-6 mb-4">
                        <label>Subject</label>
                        <input type="text" placeholder="Enter Subject" name='subject' />
                      </div>
                      <div className="mb-4">
                        <label>Message</label>
                        <textarea type="text" rows={4} placeholder="Enter Message" name='message' />
                      </div>

                      
                      <div className="send-button">
                       

                        <button className="nav-btn">Send</button>

                      </div>


                    </div>
                  </form>
                </div>
              </div>

            </div>

          </div>

        </div >
      </div>
    </div>
  ) : "";
}

export default FloatingPopup
