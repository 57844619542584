import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export default function OurTeamModel({ show, handleClose, modelData }) {
    return (
        <Modal size={'md'} show={show} onHide={handleClose} closeButton className="bg-transparent">
            <Modal.Header closeButton>
                <Modal.Title>Our Team</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <center>
                    <img src={modelData.img} className={`our-team-model d-block mb-3 ${modelData.name === "Bilal Haji" ? "bilal" : ""}`} alt="" />
                    <h3 classname="mt-3">{modelData.name}</h3>
                </center>
                {/* <p className='text-justify ' style={{margin:"auto" , maxWidth:"40rem"}} >
                {modelData.description} /* This was a real code for all show content *** /
                </p> */}
                {/* This code for only bedi sir (Start here)*/}
                <p className='text-justify ' style={{ margin: "auto", maxWidth: "40rem" }} >
                    <p>{modelData.descriptionOne}  </p>
                    <p>{modelData.descriptionTwo}  </p>
                    <p>{modelData.descriptionThree}  </p>
                </p>
                {/* This code for only bedi sir (End here)*/}
                <div className="d-flex justify-content-center">
                    {/* <a href=""  rel="noreferrer"> */}
                        {/* <Button variant="outline-primary" className="btn-sm px-4 py-2 mx-2"> */}
                        <i style={{ fontSize: "1.4rem", margin: ".8rem" }} className="fab fa-facebook-f"></i>
                        {/* </Button> */}
                    {/* </a> */}
                    {/* <a href="" target="_blank" rel="noreferrer"> */}
                        {/* <Button variant="outline-primary" className="btn-sm px-4 py-2 mx-2"> */}
                        <i style={{ fontSize: "1.4rem", margin: ".8rem" }} className="fab fa-twitter"></i>
                        {/* </Button> */}
                    {/* </a> */}
                    {/* <a href="" target="_blank" rel="noreferrer"> */}
                        {/* <Button variant="outline-primary" className="btn-sm px-4 py-2 mx-2"> */}
                        <i style={{ fontSize: "1.4rem", margin: ".8rem" }} className="fab fa-linkedin-in"></i>
                        {/* </Button> */}
                    {/* </a> */}
                </div>
            </Modal.Body>
        </Modal>
    )
}
