import React, { useState } from "react";
import { Link } from "react-router-dom";

function EnquiryStructuralAudit() {
  const [isFormOpen, setFormOpen] = useState(false);
  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const hiddenFileInput = React.useRef(null);
  const [file1, setFile1] = useState();
  const [filename1, setFilename1] = useState();
  const hiddenFileInput1 = React.useRef(null);
  const [file2, setFile2] = useState();
  const [filename2, setFilename2] = useState();
  const hiddenFileInput2 = React.useRef(null);
  const [file3, setFile3] = useState();
  const [filename3, setFilename3] = useState();
  const hiddenFileInput3 = React.useRef(null);
  const [buildingTypes, setBuildingTypes] = useState("residential");
  const [turbos, setTurbos] = useState("no");

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function handleChange(e) {
    setFilename(e.target.files[0].name);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const handleClick1 = (event) => {
    hiddenFileInput1.current.click();
  };

  function handleChange1(e) {
    setFilename1(e.target.files[0].name);
    setFile1(URL.createObjectURL(e.target.files[0]));
  }

  const handleClick2 = (event) => {
    hiddenFileInput2.current.click();
  };

  function handleChange2(e) {
    setFilename2(e.target.files[0].name);
    setFile2(URL.createObjectURL(e.target.files[0]));
  }

  const handleClick3 = (event) => {
    hiddenFileInput3.current.click();
  };

  function handleChange3(e) {
    setFilename3(e.target.files[0].name);
    setFile3(URL.createObjectURL(e.target.files[0]));
  }

  const handleBuildingTypes = (e) => {
    const getBuildingTypes = e.target.value;
    setBuildingTypes(getBuildingTypes);
  };

  const handleTurbos = (e) => {
    const getTurbos = e.target.value;
    setTurbos(getTurbos);
  };

  const toggleFormOpen = () => {
    setFormOpen(!isFormOpen);
  };

  return (
    <>
      <div className="contact-area">
        <div className="contact-form">
          <div className="col-lg-12 pt-4 pb-4">
            <div className="px-4">
              <div className="form-title mb-4">
                <h1>Structural Audit</h1>
              </div>
              <form action="">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Building Type</label>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="buildings"
                      value="residential"
                      onClick={handleBuildingTypes}
                      id="flexRadioDefault181"
                      checked={buildingTypes === "residential"}
                    />
                    <label
                      className="form-check-label me-4"
                      for="flexRadioDefault181"
                    >
                      Residential
                    </label>
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="buildings"
                      value="commercial"
                      onClick={handleBuildingTypes}
                      id="flexRadioDefault182"
                    />
                    <label
                      className="form-check-label me-4"
                      for="flexRadioDefault182"
                    >
                      Commercial
                    </label>
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="buildings"
                      value="both"
                      onClick={handleBuildingTypes}
                      id="flexRadioDefault183"
                    />
                    <label className="form-check-label" for="flexRadioDefault183">
                      Both
                    </label>
                  </div>

                  {buildingTypes === "residential" && (
                    <>
                      <div className="col-lg-6 mb-3">
                        <label>Storey Height</label>
                        <input type="text" placeholder="Enter Storey Height" />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label>Number Of Flats</label>
                        <input
                          type="text"
                          placeholder="Enter Number Of Flats"
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div>
                          <label>Area of Flats</label>
                        </div>
                        <span className="upld-btn" onClick={handleClick}>
                          <i className="bi bi-upload"></i> Upload Drawing
                        </span>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label>Age Of Structure</label>
                        <input
                          type="text"
                          placeholder="Enter Age Of Structure"
                        />
                      </div>
                      <div className="col-lg-6 mb-5">
                        <label>When Was The Last Repair?</label>
                        <input
                          type="text"
                          placeholder="Enter When Was The Last Repair?"
                        />
                      </div>
                    </>
                  )}
                  {buildingTypes === "commercial" && (
                    <>
                      <div className="col-lg-6 mb-3">
                        <label>Storey Height</label>
                        <input type="text" placeholder="Enter Storey Height" />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <div className="mb-0">
                          <label>Area of Flats</label>
                        </div>
                        <span className="upld-btn" onClick={handleClick1}>
                          <i className="bi bi-upload"></i> Upload Drwaing
                        </span>
                        <input
                          type="file"
                          ref={hiddenFileInput1}
                          onChange={handleChange1}
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label>Age Of Structure</label>
                        <input
                          type="text"
                          placeholder="Enter Age Of Structure"
                        />
                      </div>
                      <div className="col-lg-6 mb-5">
                        <label>When Was The Last Repair?</label>
                        <input
                          type="text"
                          placeholder="Enter When Was The Last Repair?"
                        />
                      </div>
                    </>
                  )}
                  {buildingTypes === "both" && (
                    <>
                      <div className="col-lg-6 mb-3">
                        <label>Storey Height</label>
                        <input type="text" placeholder="Enter Storey Height" />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label>Number Of Flats &amp; Shops</label>
                        <input
                          type="text"
                          placeholder="Enter Number Of Flats and Shops"
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="mb-0">
                          <label>Area of Flats</label>
                        </div>
                        <span className="upld-btn" onClick={handleClick2}>
                          <i className="bi bi-upload"></i> Upload Drawing
                        </span>
                        <input
                          type="file"
                          ref={hiddenFileInput2}
                          onChange={handleChange2}
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <div className="mb-0">
                          <label>Area of Shops</label>
                        </div>
                        <span className="upld-btn" onClick={handleClick3}>
                          <i className="bi bi-upload"></i> Upload Drawing
                        </span>
                        <input
                          type="file"
                          ref={hiddenFileInput3}
                          onChange={handleChange3}
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label>Age Of Structure</label>
                        <input
                          type="text"
                          placeholder="Enter Age Of Structure"
                        />
                      </div>
                      <div className="col-lg-6 mb-5">
                        <label>When Was The Last Repair?</label>
                        <input
                          type="text"
                          placeholder="Enter When Was The Last Repair?"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="">
                  <Link className="nav-btn" to="/">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnquiryStructuralAudit;
