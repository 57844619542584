import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "./PageNotFound.css"
import { Link } from "react-router-dom";

function PageNotFound(){
    return(
        <>
        <Header />


        <div id="notfound">
		<div className="notfound">
			<div className="notfound-404">
				<h1>404</h1>
				<h2>Page not found</h2>
			</div>

           <Link to="/">Head Back Home</Link> 

		</div>
	</div>

  
        </>
    )
}

export default PageNotFound