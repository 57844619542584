const RealStateProjectdata = [
    {
        id: 1,
        name: '1004 Colborne',
        mainImg: 'assets/20CHARLES/20CHA.jpeg',
        oneImg: 'assets/20CHARLES/20CH1.jpeg',
        twoImg: 'assets/20CHARLES/GetMedia2.jpg',
        threeImg: 'assets/20CHARLES/GetMedia3.jpg',
        fourImg: 'assets/20CHARLES/GetMedia4.jpg',
        fiveImg: 'assets/20CHARLES/GetMedia5.jpg',
        sixImg: 'assets/20CHARLES/GetMedia6.jpg',
    },
    {
        id: 2,
        name: '193 Drummond Street',
        mainImg: 'assets/DRUMMOND/193D1.jpeg',
        oneImg: 'assets/DRUMMOND/193D2.jpeg',
        twoImg: 'assets/DRUMMOND/193D3.jpeg',
        threeImg: 'assets/DRUMMOND/193D4.jpeg',
        fourImg: 'assets/DRUMMOND/193D.jpeg',
        fiveImg: 'assets/DRUMMOND/193D5.jpeg',
        sixImg: 'assets/DRUMMOND/193D6.jpeg',
    },
    {
        id: 3,
        name: '20 Charles Street',
        mainImg: 'assets/20CHARLES/20CHA.jpeg',
        oneImg: 'assets/20CHARLES/20CH1.jpeg',
        twoImg: 'assets/20CHARLES/GetMedia2.jpg',
        threeImg: 'assets/20CHARLES/GetMedia3.jpg',
        fourImg: 'assets/20CHARLES/GetMedia4.jpg',
        fiveImg: 'assets/20CHARLES/GetMedia5.jpg',
        sixImg: 'assets/20CHARLES/GetMedia6.jpg',
    },
    {
        id: 4,
        name: '218 Mount Pleasamt',
        mainImg: 'assets/DRUMMOND/193D1.jpeg',
        oneImg: 'assets/DRUMMOND/193D2.jpeg',
        twoImg: 'assets/DRUMMOND/193D3.jpeg',
        threeImg: 'assets/DRUMMOND/193D4.jpeg',
        fourImg: 'assets/DRUMMOND/193D5.jpeg',
        fiveImg: 'assets/DRUMMOND/193D1.jpeg',
        sixImg: 'assets/DRUMMOND/193D6.jpeg',
    },
    {
        id: 5,
        name: 'Briyani Bar',
        mainImg: 'assets/biryaniBar/biryaniBarFive.jpeg',
        oneImg: 'assets/biryaniBar/biryaniBarOne.jpeg',
        twoImg: 'assets/biryaniBar/biryaniBarTwo.jpeg',
        threeImg: 'assets/biryaniBar/biryaniBarThree.jpeg',
        fourImg: 'assets/biryaniBar/biryaniBarFour.jpeg',
        fiveImg: 'assets/biryaniBar/biryaniBarFive.jpeg',
        sixImg: 'assets/biryaniBar/biryaniBarSix.jpeg',
    }
]

export default RealStateProjectdata;