import OilGas1 from './../Images/oil-gas1.png';

// import OilGas2 from 'assets/Img/oil-gas2.png';


const ProductsData =[
    // {
    //     id:1,
    //     service:"Ammonia plant",
    //     product:"Ammonia plant",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/ammonia-plant.jpg"
    // },
    // {
    //     id:2,
    //     service:"IOCL Assam",
    //     product:"IOCL Assam",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-Assam.jpg"
    // },
    // {
    //     id:3,
    //     service:"IOCL Assam",
    //     product:"3d box 1",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-Assam-01.jpg"
    // },
    // {
    //     id:4,
    //     service:"IOCL",
    //     product:"IOCL",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-project-pic.jpg"
    // },
    // {
    //     id:5,
    //     service:"IOCL",
    //     product:"IOCL",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/Oil-and-Gas-pics/IOCL-project-pic1.jpg"
    // },
    // {
    //     id:6,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim1.png"
    // },
    // {
    //     id:7,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim2.png"
    // },
    // {
    //     id:8,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim3.png"
    // },
    // {
    //     id:9,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"bim",
    //     image:"assets/website-images/BIM-pics/bim4.png"
    // },
    // {
    //     id:10,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim5.png"
    // },
    // {
    //     id:11,
    //     service:"BIM",
    //     product:"BIM",
    //     category:"oil-and-gas",
    //     image:"assets/website-images/BIM-pics/bim6.png"
    // },
    {
        id:12,
        Data:{
            p1:"“Bedi Group” takes pride in manufacturing and packing a wide variety of frozen samosas. Our samosas are a testament to our commitment to quality, offering a delightful culinary experience right from your freezer.",
            p2:"Each samosa is meticulously prepared using high-quality ingredients. The fillings range from traditional to innovative, catering to a variety of taste preferences. Whether you prefer the classic potato and pea filling or wish to try something unique, we have a samosa for you.",
            p3:"Our frozen samosas are easy to prepare and serve, making them a convenient choice for quick meals or snacks. They are packed with care to ensure they retain their flavor and freshness until you’re ready to enjoy them.",
            p4:"At “Bedi Group”, we strive to bring the authentic taste of Indian cuisine to your home. Our frozen samosas are a reflection of this endeavor, offering a taste of India’s rich culinary heritage in every bite",
            image1:"assets/website-images/Oil-and-Gas-pics/samosa.jpeg",
            image2:"assets/website-images/Oil-and-Gas-pics/samosaaa.jpeg",      
        },
        service:"Samosa",
        product:"Samosa",
        category:"Restaurants",
        image:"assets/website-images/frozen/food.png",
        span:"Bedi Group specializes in a diverse range of samosas, offering an array of flavors and fillings to tantalize taste buds.",
},
    {
        id:13,
        Data:{
            p1:"“Bedi Group” also specializes in the production and packaging of a wide variety of frozen dimsum. Our dimsums are a culinary delight, offering a taste of authentic Asian cuisine right from your freezer.",
            p2:"Each piece of dimsum is carefully prepared using high-quality ingredients. We offer a range of fillings, catering to diverse taste preferences. Whether you’re a fan of traditional fillings or looking for something unique, we have a dimsum for you.",
            p3:"Our frozen dimsums are easy to prepare and serve, making them a convenient choice for quick meals or snacks. They are packed with care to ensure they retain their flavor and freshness until you’re ready to enjoy them.",
            p4:"At “Bedi Group”, we strive to bring the authentic taste of Indian cuisine to your home. Our frozen Dimsums are a reflection of this endeavor.",
            image1:"assets/website-images/Oil-and-Gas-pics/dimsum.jpeg",
            image2:"assets/website-images/Oil-and-Gas-pics/dimsum1.jpeg",
        },
        service:"Dimsum",
        product:"Dimsum",
        category:"Restaurants",
        image:"assets/website-images/frozen/dimsum.webp",
        span:"Bedi Group specializes in a diverse range of Dimsums, offering an array of flavors and fillings to tantalize taste buds.",
},
    {
        id:14,
        Data:{
            p1:"Bedi Group is proud to present our range of sauces, each packed with a variety of flavors that cater to diverse taste preferences. Our sauces are manufactured with the utmost care, ensuring the highest quality in every bottle.",
            p2:"Each sauce is crafted from carefully selected ingredients, resulting in a burst of flavor that can elevate any meal. Whether you’re looking for a spicy kick, a tangy twist, or a sweet and savory blend, we have a sauce for you.",
            p3:"Our sauces are packed in a way that preserves their freshness and flavor, allowing you to enjoy the authentic taste with every use. They are a perfect addition to your pantry, ready to add a flavorful touch to your dishes whenever you need.",
            p4:"At “Bedi Group”, we believe in bringing the best to your table. Our sauces are a testament to this belief, offering a taste experience that is sure to delight. I’ve also created two images, one showcasing our range of frozen sauces and another displaying the variety of flavors we offer.",
            image1:"assets/website-images/Oil-and-Gas-pics/sauce.jpeg",
            image2:"assets/website-images/Oil-and-Gas-pics/sauce1.jpeg",
        },
        service:"Sauces",
        product:"Sauces",
        category:"Restaurants",
        image:"assets/website-images/frozen/foursauces.png",
        span:"Bedi Group specializes in a diverse range of Sauces, offering an array of flavors and fillings to tantalize taste buds.",
},
    // {
    //     id:15,
    //     service:"PEB Shed at Malad",
    //     product:"PEB-Shed-at-Angola",
    //     category:"Restaurants",
    //     image:"assets/website-images/Industrial-PEB-Projects/Shed-at-malad.jpeg"
    // },
    // {
    //     id:16,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },
    // {
    //     id:17,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal.jpg"
    // },
    // {
    //     id:18,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:19,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal1.jpg"
    // },
    // {
    //     id:20,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/Bungalow-@-Yavatamal2.jpg"
    // },
    // {
    //     id:21,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate.jpeg"
    // },
    // {
    //     id:22,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate2.jpeg"
    // },
    // {
    //     id:23,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate3.jpeg"
    // },
    // {
    //     id:24,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate4.jpg"
    // },
    // {
    //     id:25,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate5.jpg"
    // },
    // {
    //     id:26,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/shams-residency.jpg"
    // },
    // {
    //     id:27,
    //     service:"Real Estate",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate6.jpeg"
    // },

    // // .........................Audit
    // {
    //     id:28,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test.jpeg"
    // },
   
    // {
    //     id:29,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test1.jpeg"
    // },
   
    // {
    //     id:30,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test2.jpeg"
    // },
   
    // {
    //     id:31,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test3.jpeg"
    // },
   
    // {
    //     id:32,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test4.jpeg"
    // },
   
    // {
    //     id:33,
    //     service:"audit",
    //     product:"audit",
    //     category:"tructural-audit",
    //     image:"assets/website-images/structural-audit/NDT Test5.jpeg"
    // },
  
    // {
    //     id:26,
    //     service:"Oxygen Plant at Mahul Chembur",
    //     product:"3d box 2",
    //     category:"real-estate",
    //     image:"assets/website-images/Industrial-PEB-Projects/Oxygen-Plant-at-Mahul-Chembur.png"
    // },

    // sus
    // {
    //     id:34,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Realestate-projects-pics/real-estate1.jpeg"
    // },

    // {
    //     id:35,
    //     service:"sus",
    //     product:"sus",
    //     category:"sus",
    //     image:"assets/website-images/Industrial-PEB-Projects/peb1.jpg"
    // },
  
];
export default ProductsData;